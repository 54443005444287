<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-form id="change" status-icon ref="ruleForm" label-width="100px"
               class="demo-ruleForm">
        文章标题：
        <el-input
            style="width: 200px; margin-right: 10px"
            class="search"
            v-model="title"
            prefix-icon="el-icon-search"
            clearable
            placeholder="请输入文章标题"
        />
        文章状态：
        <el-select v-model="value" placeholder="请选择" clearable style="margin-right: 10px">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          >
          </el-option>
        </el-select>
        客户名称：
        <el-input
            style="width: 200px; margin-right: 10px"
            class="search"
            v-model="customerName"
            prefix-icon="el-icon-search"
            clearable
            placeholder="请输入客户名称"
        />
        <el-button type="primary" @click="getUserList()" style="background: #1d90ff; border:none">查询</el-button>
      </el-form>
    </div>
    <el-table
        stripe
        :data="tableData"
        style="width: 100%;margin-top:10px"
        border>
      <el-table-column
          align="center"
          prop="id"
          label="文章编号"
          width="100">
      </el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag effect="dark" v-show="scope.row.status == 0" type="info">待审核</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 1">已审核</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 2" type="success">已上架</el-tag>
          <el-tag effect="dark" v-show="scope.row.status == 3" type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态修改" width="100" align="center" v-if="userInfo.userType==-99">
        <!-- eslint-disable-next-line -->
        <template slot-scope="scope">
          <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="color:deepskyblue;">
                状态修改<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="adminUpdateArticleStatus(scope.row.id,0)" icon="el-icon-remove-outline"
                                style="color:gray;">待审核
              </el-dropdown-item>
              <el-dropdown-item @click.native="adminUpdateArticleStatus(scope.row.id,1)"
                                icon="el-icon-circle-plus-outline"
                                style="color:blue;">已审核
              </el-dropdown-item>
              <el-dropdown-item @click.native="adminUpdateArticleStatus(scope.row.id,2)" icon="el-icon-circle-check"
                                style="color:green;">已上架
              </el-dropdown-item>
              <el-dropdown-item @click.native="adminUpdateArticleStatus(scope.row.id,3)" icon="el-icon-circle-close"
                                style="color:red;">已下架
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <!-- eslint-disable-next-line -->
      </el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="标题"
          width="300">
      </el-table-column>
      <el-table-column
          align="center"
          prop="keyword"
          label="关键词"
          width="150">
      </el-table-column>
      <el-table-column
          align="center"
          prop="description"
          label="描述"
          width="350">
      </el-table-column>
      <el-table-column
          align="center"
          prop="nickName"
          label="撰稿人"
          width="150">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.nickName" type="info" >{{ scope.row.nickName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="customerName"
          label="所属客户"
          width="150">
        <template slot-scope="scope">
          <el-tag effect="plain" v-show="scope.row.customerName" type="success" >{{ scope.row.customerName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="updatedTime"
          label="编辑时间"
          width="150"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="success" style="background: #1d90ff; border:none" size="mini"
                     @click="editArticle(scope.row.id)"
          >编辑
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total=pagetotal>
      </el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from 'jquery';
import Cookies from "js-cookie";

export default {
  methods: {
    adminUpdateArticleStatus(id, status) {
      this.$api.post(
          "/article/admin/updateArticleStatus",
          null,
          {
            id: id,
            status: status
          },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.getArticleList();
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
    },
    getArticleList() {
      this.$api.get(
          "/article/list",
          null,
          {
            title: this.asearch1,
            status: this.value,
            audit: true
          },
          (successRes) => {
            console.log(successRes);
            this.tableData = successRes.data.rows;
            this.pagetotal = successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getUserList() {
      this.$api.get(
          "/article/list",
          null,
          {
            title: this.title,
            status: this.value,
            customerName: this.customerName,
            audit: true
          },
          (successRes) => {
            console.log(successRes);
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
              exf[i].updatedTime = exf[i].updatedTime.substring(0, 10);
            }
            this.tableData = exf;
            this.pagetotal = successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    handleSizeChange(val) {
      this.spagetotal = val;
      this.$api.get(
          "/article/list",
          null,
          {
            page: this.nowpagetotal,
            record: this.spagetotal,
            title: this.title,
            status: this.value,
            customerName: this.customerName,
          },
          (successRes) => {
            this.pagetotal = successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
              exf[i].updatedTime = exf[i].updatedTime.substring(0, 10);
            }
            this.tableData = exf;

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

      console.log(val);
    },
    handleCurrentChange(val) {
      this.nowpagetotal = val;
      this.$api.get(
          "/article/list",
          null,
          {
            page: this.nowpagetotal,
            record: this.spagetotal,
            title: this.title,
            status: this.value,
            customerName: this.customerName,
          },
          (successRes) => {
            this.pagetotal = successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i < exf.length; i++) {
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
              exf[i].updatedTime = exf[i].updatedTime.substring(0, 10);
            }
            this.tableData = exf;

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    handleClick(row) {
      console.log(row);
    },
    addArticle() {
      this.$router.push({name: 'examineAdd', params: {userId: 123}});
    },
    editArticle(id) {

      this.$router.push({path: 'examineedit', query: {id: id}});

    },
    getUserInfoByUserName() {
      const username = Cookies.get("username");
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
          },
          (successRes) => {
            console.log(successRes);
            this.userInfo = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
  data() {
    return {
      userInfo: {},
      options: [
        {
          id: 0,
          value: "选项1",
          label: "待审核",
        },
        {
          id: 1,
          value: "选项2",
          label: "已审核",
        },
        {
          id: 2,
          value: "选项3",
          label: "已上架",
        },
        {
          id: 3,
          value: "选项4",
          label: "已下架",
        },
      ],
      value: '',
      tableData: [],
      title: "",
      status: "",
      customerName: '',
      asearchdata: {},
      pagetotal: 0,
      spagetotal: '10',
      nowpagetotal: '1',
      currentPage4: 1
    }
  },
  mounted() {
    this.getUserList();
    this.getUserInfoByUserName();
  },
}
</script>